<template>
	<div class="table-responsive">
		<div class="form-group">
			<div class="row">
				<div class="col-md-9 col-xs-8">
					<input v-model="param" placeholder="Digite para pesquisar" class="form-control" @keypress="callSearch">
				</div>
				<div class="col-md-2 col-xs-2">
					<a type="button" @click="search()" class="btn btn-dark">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
							<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
						</svg>
					</a>
					<router-link class="btn btn-dark ml-2" :to="{ name: routeNew}">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
							<path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
						</svg>
					</router-link>
				</div>
			</div>
		</div>
		
		<table class="table table-striped table-sm">
			<thead>
				<tr>
					<th v-for="field in fields" :key="field.name" :class="(field.name == 'id') ? 'd-none' : ''">
						<button class="no-border" @click="recordsSort(field.name)" >{{ field.caption }} </button>
					</th>
					<th>Ações</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="record in records" :key="record.id">
					<td v-for="field in fields" :key="field.name" :class="(field.name == 'id') ? 'd-none' : ''" >{{ field.alias ? field.alias[Number(record[field.name] || 0)] : record[field.name] }}</td>	
					<td>
						<router-link class="btn btn-dark" :to="{ name: routeTo, params: { id: record.id }}" v-if="!routeToUrl" >
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
								<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
								<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
							</svg>
						</router-link>
						<!-- <a :href="routeToUrl + record.id" class="btn btn-success" v-if="routeToUrl"><span class="glyphicon glyphicon-pencil"></span></a>
						<a type="button" :href="'/pessoas/atendimento/' + record.id" data-toggle="tooltip" title="" class="btn btn-success hidden-xs" data-original-title="Prontuário" v-if="object == 'people'"><span class="glyphicon glyphicon-list-alt"></span></a> -->
					</td>
				</tr>
			</tbody>
		</table>
		<div class="container">
			<div class="col-md-12 text-center">
				<button type="button" class="btn btn-sm btn-outline-secondary" @click="page = 1"> &lt;&lt; </button>
				<button type="button" class="btn btn-sm btn-outline-secondary" v-if="page != 1" @click="page--"> Anterior </button>
				<button type="button" class="btn btn-sm btn-outline-secondary" :class="pageNumber === page && 'bg-theme3'" v-for="pageNumber in pagesLoaded" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
				<button type="button" @click="page++" v-if="page < pages && pages" class="btn btn-sm btn-outline-secondary"> Próximo </button>
				<button type="button" class="btn btn-sm btn-outline-secondary" @click="page = pages"> >> </button>
			</div>
		</div>
	</div>
</template>

<script>
import { sAlert } from '../utils/jalerts';
import ApiAxios from '../../services/api-axios';

export default {
	props: {
		object: String,
		fields: Array,
		routeNew: String,
		routeTo: String,
		filter: String,
		routeToUrl: String,
	},
	components: {
	},
	data() {
		return {
			records: [],
			strFields: '',
			page: 1,
			size: 10,
			pages: 0,
			sort: '',
			param: '',
		};
	},
	watch: {
		page() {
			this.fetchGetData();
		}
	},
	computed: {
		pagesLoaded() {
			let pagesVisible = [];
			
			let first = this.page - 3 > 1 ? this.page - 3 : 1;
			let last = this.page + 3 < this.pages ? this.page + 3 : this.pages;

			for (let i = first; i <= last; i++) { pagesVisible.push(i); }

			return pagesVisible;
		},
		filters() {
			if (this.param) return { [this.filter]: this.param };

			return {};
		},
	},
	methods: {
		callSearch(e) {
			if (e.keyCode === 13) this.search();
		},
		async fetchGetData() {
			this.$root.loadingVisible = true;
			ApiAxios.get(`${this.object}`, {
				params: {
					fields: this.strFields,
					page: this.page - 1,
					sort: this.sort,
					filter: this.filters,
				}
			})
			.then((res) => {
				this.records = res.data[this.object];
				this.pages = res.data.totalPages;
				this.$root.loadingVisible = false;
			})
			.catch((e) => {
				this.$root.loadingVisible = false;
				if (e.response.status == 403) {
					this.$router.back();
					return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
				}
				sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
			});
		},
		fieldNames() {
			let names;
			this.fields.forEach(el => {
				if (!names) { names = el.name; } 
				else { names += `,${el.name}`; }
			});
			return names;
		},
		recordsSort(fieldName) {
			this.sort = fieldName;
			if(this.page === 1) { return this.fetchGetData(); }
			this.page = 1;
		},
		search() {
			if(this.page === 1) { return this.fetchGetData(); }
			this.page = 1;
		},
	},
	created () {
		this.strFields = this.fieldNames();
		
		this.fetchGetData();
	}
}
</script>
